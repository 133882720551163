<style>
    #chartdivHistory {
        width: 100%;
        height: 500px;
    }
</style>

<template>
  <v-container
    class="container fill-height grid-list-xl"
    fluid
    align-start
    justify-start
  >
    <v-row>
      <v-col
        cols="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-container
          grid-list-xs
          class="mt-0 mb-0 pt-0 pb-0"
        >
          <v-list-item two-line>
            <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item-title><h2>Clientes</h2></v-list-item-title>
              <v-list-item-subtitle>Clientes del usuario </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-container>
      </v-col>

      <v-col
        cols="12"
        class="mt-0 pt-0"
      >
        <v-container grid-list-xs>
          <!-- eslint-disable -->
                <v-data-table
                    :headers="headers"
                    :items="desserts"
                    item-key="name"
                    class="elevation-1"
                    :search="search"
                    :custom-filter="filterOnlyCapsText"
                    :loading='loadTable'
                    loading-text="Cargando... por favor espere"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    >
                    <template v-slot:top >
                        <v-layout row ma-1>
                            <v-flex md6>
                                <v-dialog ref="dialogDesde" v-model="inp_desde" full-width :return-value.sync="desde"  width="290px">
                                    <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="desde"
                                        label="Creado"
                                        prepend-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="desde" locale="co-es" scrollable>
                                    <div class="flex-grow-1"></div>
                                    <v-btn text color="primary" @click="inp_desde = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.dialogDesde.save(desde); updateDate()">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-flex>
                            <v-flex md6>
                                <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-icon small class="mr-2" @click="editarCliente( item)">
                            create
                        </v-icon>
                    </template>
                </v-data-table>

        </v-container>
        </v-col>
    </v-row>
    <dialogSettings  ref="DialogSettings" :update="tablaClientes" ></dialogSettings>
    </v-container>
</template>

<script>
import dialogSettings from '../tools/fullsizeDialog'
import { mapGetters } from 'vuex'
export default {
	/* eslint-disable */
  components: {
    dialogSettings
  },
  data: () => ({
    desde: new Date().toISOString().substr(0, 10),
    inp_desde: false,
    search: '',
    calories: '',
    desserts: [],
    loadTable: true,
    loadCsv: false,
    sortBy: 'created',
    sortDesc: true
  }),
  computed: {
    headers () {
      return [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Rif', value: 'nrorif' },
        { text: 'Fecha', align: 'left', value: 'created' },
        { text: 'Accion', sortable: false, value: 'action' }
      ]
    },
    ...mapGetters({
      user: 'user',
      clientesData: 'clientesData'
    })
  },
  methods: {
    filterOnlyCapsText (value, search, item) {
      return value != null &&
            search != null &&
            typeof value === 'string' &&
            value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },

    updateDate: function () {
      this.tablaClientes()
    },
    /// ////////////////////////////////////////////
    setLoadData () {
      var table = {}
      table.user = this.user.id
      return table
    },
    tablaClientes: function () {
      this.desserts = []
      this.loadTable = true
      this.$store
        .dispatch('getClientes', this.setLoadData())
        .then(() => this.evalueResp())
        .catch(err => { console.log(err); this.loadTable = false })
    },
    evalueResp () {
      this.desserts = this.clientesData
      this.loadTable = false
    },
    /// //////////////////////////////////////////
    editarCliente (data) {
      this.$refs.DialogSettings.show("clientesForm", data, 'Cliente')
    }

  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  created: function () {
    this.tablaClientes()
  }

}

</script>
