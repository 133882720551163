<template>
  <div>
    <v-container
      class="container fill-height grid-list-xl"
      fluid
      align-start
      justify-start
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs10
          offset-xs1
        >
          <v-text-field
            v-model="valores.nombre"
            :rules="nameRules"
            :counter="200"
            label="Nombre"
            required
          />
        </v-flex>
        <v-flex
          xs10
          offset-xs1
        >
          <v-text-field
            v-model="valores.email"
            :rules="emailRules"
            :counter="100"
            label="Email"
            required
          />
        </v-flex>
        <v-flex
          xs10
          offset-xs1
        >
          <v-text-field
            v-model="valores.nrorif"
            :rules="nrorifRules"
            :counter="25"
            label="Rif"
            required
          />
        </v-flex>
        <v-flex
          xs10
          offset-xs1
        >
          <v-textarea
            v-model="valores.direccion"
            :rules="direccionRules"
            :counter="255"
            label="Direccion"
            hint="Direccion"
          />
        </v-flex>

        <v-flex
          xs10
          offset-xs1
        >
          <v-text-field
            v-model="valores.telefono_movil"
            :rules="telefono_movilRules"
            :counter="150"
            label="Telefono movil"
            required
          />
        </v-flex>
        <v-flex
          xs10
          offset-xs1
        >
          <v-text-field
            v-model="valores.telefonos"
            :rules="telefonosRules"
            :counter="150"
            label="Telefonos"
            required
          />
        </v-flex>
        <v-flex
          xs10
          offset-xs1
          style="margin-top:15px"
        >
          <v-layout
            row
            wrap
          >
            <v-flex
              xs4
              offset-xs1
            >
              <v-btn
                block
                style="min-height: 50px"
                color="success"
                @click="updateCliente()"
              >
                Enviar
              </v-btn>
            </v-flex>
            <v-flex
              xs4
              offset-xs2
            >
              <v-btn
                block
                style="min-height: 50px"
                color="error"
                @click="finishCliente(false)"
              >
                Cancelar
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
	props: ['data', 'close'],
	data: () => ({
		valid: false,
		valores: {
			nombre: '',
			email: '',
			direccion: '',
			nrorif: '',
			telefono_movil: '',
			telefonos: ''
		},
		nameRules: [
			v => !!v || 'Name is required',
			v => v.length <= 200 || 'Name must be less than 10 characters'
		],
		emailRules: [
			v => !!v || 'E-mail is required',
			v => /.+@.+/.test(v) || 'E-mail must be valid',
			v => v.length <= 100 || 'E-mail must be less than 10 characters'
		],
		nrorifRules: [
			v => !!v || 'Rif is required',
			v => v.length <= 25 || 'Rif must be less than 10 characters'
		],
		direccionRules: [
			v => v.length <= 255 || 'Direccion must be less than 10 characters'
		],
		telefono_movilRules: [
			v => !/[a-zA-Z]/g.test(v) || 'Telefono must be valid',
			v => v.length <= 150 || 'Telefono must be less than 10 characters'
		],
		telefonosRules: [
			v => !/[a-zA-Z]/g.test(v) || 'Telefonos must be valid',
			v => v.length <= 150 || 'Telefonos must be less than 10 characters'
		]
	}),
	mounted () {
		this.setCliente()
	},
	methods: {
		setCliente () {
			this.valores = this.$props.data
		},
		updateCliente () {
			// console.log(JSON.stringify(this.valores))
			this.$store
				.dispatch('setCliente', this.valores)
				.then(() => this.finishCliente(true))
				.catch(err => {
					console.log(err)
				})
		},
		finishCliente (status) {
			this.close(status)
		}

	}
}
</script>
