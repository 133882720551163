<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="hide(false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <component
          :is="component"
          v-if="ifCompGo"
          :close="hide"
          :data="data"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import clientesForm from '../clientes/clientForm.vue'

export default {
	components: {
		clientesForm
	},
	props: {
		update: {
			type: Function
		}
	},
	data () {
		return {
			dialog: false,
			notifications: false,
			sound: true,
			widgets: false,
			data: {},
			component: '',
			title: '',
			ifCompGo: false
		}
	},
	methods: {
		show (componente, data, title) {
			this.component = componente
			this.data = data
			this.title = title
			this.dialog = true
			this.ifCompGo = true
		},
		hide (status) {
			this.ifCompGo = false
			this.dialog = false
			if (status) this.update()
		}

	}
}
</script>
